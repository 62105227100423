@import "https://fonts.googleapis.com/css?family=Poppins:400,600,800&display=swap";
@font-face {
  font-family: Gotham Rounded;
  font-style: normal;
  font-weight: normal;
  src: local(Gotham Rounded), url("Gotham-Rounded-Bold.ae385136.woff") format("woff");
}

:root {
  --background: #fff;
  --primary-color: #6adfa7;
  --secondary-color: #6ec2ee;
  --primary-middle-color: #6fdcc5;
  --secondary-middle-color: #6ed2d5;
  --secondary-text-color: #4ea2ce;
  --dark-color: #202020;
  --gray-color: #aaa;
  --link-hover: #1482b5;
  --btn-background: #6ebbf9;
  --btn-background-hover: #5eabe9;
  --btn-color: white;
  --footer-color: white;
  --alert-background: #ff2ad4;
  --alert-color: white;
  --warning-background: #777;
  --warning-background-hover: #ef4040;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  color: #000;
  background: var(--background);
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 18px;
}

a {
  color: #333;
  text-decoration: none;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
}

.content_inside {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
}

.brand_gradient {
  background: linear-gradient(130deg, var(--secondary-color), var(--primary-color));
  padding-top: 9vw;
  padding-bottom: 9vw;
  position: relative;
}

.gradient_top {
  width: 100%;
  height: 9vw;
  position: absolute;
  top: -1px;
  left: 0;
}

.gradient_bottom {
  width: 100%;
  height: 5vw;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(-1);
}

.gradient_right {
  width: 16vw;
  height: 16vw;
  position: absolute;
  top: 5vw;
  right: 0;
}

.gradient_what_why {
  width: 100%;
  height: 7vw;
  position: absolute;
  top: -6vw;
  left: 0;
  transform: scale(-1);
}

.flex {
  align-items: center;
  display: flex;
}

section {
  min-height: 100vh;
  padding: 40px 0;
  position: relative;
}

section h2 {
  max-width: 75%;
}

nav {
  width: 100%;
  z-index: 4;
  background: #fff;
  flex-direction: column;
  padding: 10px 0;
  display: flex;
  position: fixed;
}

.nav_menu {
  text-align: center;
  flex: 1;
}

.btn_lng {
  margin: 0;
}

.btn_lng:first-child:after {
  content: "/";
  margin-left: 5px;
  margin-right: 2px;
  font-size: .8em;
}

.side-menu, .hamb {
  display: none;
}

.nav_wircos {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  font-family: Gotham Rounded;
  font-size: 1.75em;
  line-height: 1;
}

nav a {
  font-size: .8em;
}

.nav_menu a {
  padding: 0 16px;
  position: relative;
}

.nav_menu a:after {
  content: "|";
  color: #777;
  font-size: .75em;
  position: absolute;
  top: 4px;
  right: 0;
}

.nav_menu a.last:after {
  content: "";
}

nav a:hover {
  color: gray;
}

.disabled, .disabled:hover {
  pointer-events: none;
  color: gray !important;
}

header {
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .5s;
  display: flex;
  overflow: hidden;
}

.header_hide {
  opacity: 0;
}

.header_text {
  flex: 1;
}

.highlight {
  color: var(--primary-color);
  font-weight: inherit;
}

.header_lost {
  flex: 1;
  position: relative;
}

.header_lost img {
  width: 100%;
  padding-left: 20px;
}

.lost_background {
  z-index: -1;
  opacity: .75;
  position: absolute;
}

h1 {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  font-size: 3.5em;
  font-weight: bold;
  line-height: 1.2;
}

h1 small {
  display: block;
}

h2 {
  margin-top: 20px;
  padding-right: 50px;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.4;
}

.btn_default {
  color: #fff;
  background-color: var(--btn-background);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 25px;
  margin: 30px 0 0;
  padding: 10px 40px;
  text-decoration: none;
  transition: background .5s;
  display: block;
}

.btn_disabled {
  opacity: .5;
  pointer-events: none;
}

.btn_default:hover {
  background-color: var(--btn-background-hover);
  text-decoration: none;
}

.btn_disabled:hover {
  background: #d3d3d3;
}

.btn_warning {
  background: var(--warning-background);
}

.btn_warning:hover {
  background: var(--warning-background-hover);
}

.header_logo {
  width: 150px;
}

.row {
  align-items: top;
  width: 100%;
  gap: 40px;
  display: flex;
}

.col {
  flex: 1;
  padding: 20px 0;
}

#how_works .row {
  line-height: 1.2;
}

#how_works .col {
  text-align: center;
}

.works_image {
  width: 100%;
  max-width: 250px;
  margin: 0 auto 20px;
  display: block;
}

#why_section {
  background: #f0f0f0;
  padding-bottom: 9vw;
  position: relative;
}

.row_features {
  margin: 70px 0 50px;
}

.feature_1 {
  background: var(--primary-color);
}

.feature_2 {
  background: var(--primary-middle-color);
}

.feature_3 {
  background: var(--secondary-middle-color);
}

.feature_4 {
  background: var(--secondary-color);
}

.row_features .col {
  background: #fff;
  border-radius: 10px;
  padding: 0;
  box-shadow: 0 4px 12px #00000026;
}

.feature_header {
  color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  font-size: 1em;
  font-weight: 600;
}

.feature_body {
  background: #fff;
  border-radius: 0 0 10px 10px;
  padding: 40px 20px;
  font-size: .9em;
}

.row_plans {
  gap: 50px;
  margin: 100px 0 80px;
}

.row_plans .col:last-child {
  flex: 2;
}

.row_the_plans {
  gap: 20px;
}

.plan_col {
  background: #fff;
  border-radius: 10px;
  flex: 1;
  padding: 0 0 20px;
  box-shadow: 0 4px 12px #00000026;
}

.plan_title {
  color: #fff;
  background: var(--primary-color);
  border-radius: 10px 10px 0 0;
  padding: 15px 20px;
  font-weight: bold;
}

.plan_feature {
  color: gray;
  border-bottom: 1px solid #ddd;
  padding: 8px 20px 5px;
  font-size: .8em;
}

.plan_feature.centered {
  text-align: center;
  margin-top: 30px;
}

.plan_feature:last-child {
  border-bottom: none;
}

.plan_feature_value {
  color: #000;
  font-size: 1.25em;
  font-weight: 600;
}

.row_plans .btn_default {
  width: 100%;
  text-align: center;
}

#faq {
  background: #f0f0f0;
  padding-top: 9vw;
  position: relative;
}

.gradient_faq {
  width: 100%;
  height: 9vw;
  z-index: 2;
  position: absolute;
  bottom: -9vw;
  left: 0;
}

.faq_title {
  color: var(--secondary-text-color);
  margin-top: 30px;
  margin-bottom: 20px;
}

.faq_question {
  cursor: pointer;
  background: #fff;
  border-radius: 25px;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 10px 15px;
  font-weight: 600;
  display: flex;
}

.faq_question_text {
  flex: 1;
}

.faq_arrow {
  width: 15px;
  height: 15px;
  border: 4px solid var(--primary-color);
  cursor: pointer;
  border-top: 0;
  border-left: 0;
  margin-right: 5px;
  transition: all .25s;
  display: block;
  transform: rotate(45deg);
}

.faq_arrow_extend .faq_arrow {
  transform: rotate(230deg);
}

.faq_answer {
  margin-bottom: 30px;
  padding: 0 20px;
  line-height: 1.4;
  display: none;
}

.faq_answer_visible {
  display: block;
}

footer {
  color: var(--footer-color);
  background: linear-gradient(130deg, var(--secondary-color), var(--primary-color));
  padding: 15vw 0 100px;
  display: flex;
}

.gradient_footer {
  width: 100%;
  height: 20vw;
  position: absolute;
  bottom: -20vw;
  left: 0;
}

.share_section {
  gap: 10px;
  margin-bottom: 30px;
  display: flex;
  position: relative;
}

.share_section a {
  line-height: 1;
  display: block;
}

.share_section svg {
  width: 45px;
  height: 45px;
  fill: #fff;
  display: block;
}

footer .row {
  justify-content: center;
  align-items: center;
}

.footer_text {
  max-width: 50%;
  margin-top: 20px;
  line-height: 1.2;
}

.footer_link {
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 1px solid #fff;
  margin: 15px 0;
  padding-bottom: 2px;
  font-size: .9em;
  text-decoration: none;
  display: block;
}

#how_works svg {
  width: 90px;
  height: 90px;
  fill: #fff;
  background: #0088c8;
  border-radius: 50%;
  margin: 0 auto 20px;
  padding: 20px;
  display: block;
}

.policy {
  padding: 100px 0 150px;
  font-size: .9em;
}

.policy h2 {
  margin-top: 50px;
  font-size: 1.5em;
  font-weight: 600;
}

.policy h3 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 600;
}

.text_gradient {
  background: linear-gradient(95deg, var(--secondary-color), var(--primary-color));
  background-position: 0 0;
  background-size: 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hide, .hidden {
  display: none !important;
}

.ashow {
  display: block !important;
}

.lng {
  display: none;
}

.lng_en {
  display: inherit;
}

.mobile {
  display: none;
}

.no_mobile {
  display: block;
}

#upload_nft {
  padding-top: 80px;
}

.upload_link {
  color: var(--primary-color);
  font-weight: 600;
  text-decoration: underline;
}

.upload_form {
  border: 2px solid var(--secondary-color);
  background: #f3f3f3;
  border-radius: 10px;
  padding: 25px 25px 20px;
  box-shadow: 0 4px 12px #00000026;
}

.account_section .upload_form {
  box-shadow: none;
  background: none;
  border: none;
  padding: 0;
}

.upload_row {
  gap: 40px;
  display: flex;
}

.upload_col_1 {
  flex: 1;
}

.upload_col_2 {
  flex: 3;
}

label {
  color: gray;
  margin-bottom: 3px;
  font-size: .8em;
  display: block;
}

input, textarea, select {
  width: 100%;
  resize: vertical;
  border: 1px solid #d3d3d3;
  outline: none;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-family: Poppins, sans-serif;
  font-size: .9em;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
  background-color: #0000;
  transition: background-color 5000s ease-in-out;
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

fieldset {
  border: none;
}

.input_row {
  align-items: baseline;
  display: flex;
}

.input_row input {
  margin-right: 10px;
}

.input_type {
  color: gray;
  font-size: 1.2em;
}

.upload_thumb_image {
  width: 100%;
  background: #fff;
  border: 1px solid #d3d3d3;
  margin-bottom: 20px;
  padding-top: 100%;
  display: block;
  position: relative;
}

.upload_thumb_content {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.upload_thumb_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0;
  left: 0;
}

.upload_select_icon {
  width: 60px;
  fill: gray;
  transition: all .5s;
}

.upload_thumb_image:hover .upload_select_icon {
  width: 70px;
  fill: #000;
}

.upload_btn {
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  background: gray;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px 10px;
  font-size: .9em;
  transition: background .5s;
  display: block;
}

.upload_btn:hover {
  background: #789;
}

.upload_file_selected {
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px 10px 10px;
  font-size: .9em;
}

.upload_file_selected svg {
  width: 20px;
}

.upload_info {
  margin-top: 30px;
  font-size: 1.2em;
  font-weight: bold;
}

.nft_file_information {
  word-break: break-all;
}

#nft_filename {
  font-weight: 600;
}

#nft_filesize {
  font-size: .8em;
}

.upload_action {
  flex-direction: column;
  align-items: end;
  display: flex;
}

.upload_error {
  color: #fff;
  width: 100%;
  background: #d04040;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px 10px;
  font-size: .9em;
}

.upload_success {
  color: #000;
  width: 100%;
  word-break: break-word;
  padding: 20px 15px;
  line-height: 1.4;
}

.upload_form .btn_default {
  margin-top: 30px !important;
}

.nft_checkout_link {
  word-break: break-all;
  color: var(--secondary-color);
  font-weight: 600;
  display: inline-block;
}

.nft_checkout_section {
  align-items: center;
  gap: 20px;
  margin: 20px 0 30px;
  display: flex;
}

.tooltip {
  fill: gray;
  line-height: 1;
  position: relative;
}

.tooltiptext {
  color: #fff;
  z-index: 1;
  width: max-content;
  opacity: 0;
  pointer-events: none;
  background-color: #999;
  border-radius: 6px;
  padding: 5px;
  font-size: .65em;
  transition: opacity .3s;
  position: absolute;
  bottom: -100%;
  left: 0;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}

.upload_row_fields {
  gap: 1px;
  margin: 0;
}

.upload_field {
  height: 58px;
  align-items: center;
  padding: 5px 10px;
  display: flex;
  position: relative;
}

.upload_field input, .upload_field textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: 0;
  border-bottom: 1px solid #d3d3d3;
  padding: 0 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.upload_field_textarea {
  height: 90px;
}

.upload_field label {
  z-index: 2;
  pointer-events: none;
  width: 100%;
  background: #fff;
  padding-left: 10px;
  font-size: .95em;
  transition: all .25s;
  position: absolute;
  top: 17px;
  left: 0;
}

.upload_field input:focus, .upload_field input:not(:placeholder-shown), .upload_field textarea:focus, .upload_field textarea:not(:placeholder-shown) {
  padding: 20px 10px 5px;
}

.upload_field input:focus + label, .upload_field input:not(:placeholder-shown) + label, .upload_field textarea:focus + label, .upload_field textarea:not(:placeholder-shown) + label {
  color: #777;
  padding-top: 3px;
  font-size: .75em;
  top: 0;
}

.upload_field input::-webkit-input-placeholder, .upload_field textarea::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}

.row_fields {
  display: flex;
}

.col_fields {
  flex: 1;
}

.col_fields:first-child {
  border-right: 1px solid #d3d3d3;
}

.progress {
  gap: 10px;
  font-size: .9em;
  position: relative;
}

.progress_bar_wrapper {
  width: 100%;
  height: 30px;
  background: #d3d3d3;
  border-radius: 20px;
  position: relative;
}

.progress_bar {
  height: 100%;
  width: 0;
  background: var(--primary-color);
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.progress_value {
  color: #fff;
  position: absolute;
  top: 2px;
  left: 10px;
}

.cookies {
  background: var(--primary-color);
  color: #fff;
  width: 150px;
  text-align: left;
  border-radius: 10px 10px 10px 0;
  padding: 5px 10px 10px;
  font-size: .8em;
  line-height: 1.2;
  position: absolute;
  top: 0;
  right: 0;
}

.btn_cookies {
  text-align: right;
  margin-top: 10px;
  display: block;
}

.login_section {
  max-width: 400px;
  text-align: center;
  background: #f3f3f3;
  border-radius: 10px;
  margin: 30px auto;
  padding: 20px 30px 30px;
  font-size: 17px;
  box-shadow: 0 4px 12px #00000026;
}

.login_section input {
  max-height: 44px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #32325d1a;
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 12px;
  font-size: 1em;
  box-shadow: 0 1px 1px #00000008, 0 3px 6px #00000005;
}

#login_email {
  text-align: center;
}

.login_title {
  font-size: 1.3em;
  font-weight: bold;
}

.login_info {
  text-align: left;
  margin-top: 1.2em;
  margin-bottom: 30px;
  font-size: .9em;
  line-height: 1.3;
}

.login_error {
  color: #fff;
  background: #f08080;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 5px 15px;
  font-size: .9em;
}

.login_close_window {
  text-align: left;
  margin-top: 40px;
}

.login_section .btn_default {
  width: 100%;
  margin-top: 40px;
}

.grid {
  flex-wrap: wrap;
  margin: 0 -10px 40px;
  padding: 0;
  display: flex;
}

.grid_empty {
  text-align: center;
  height: 100%;
  color: var(--primary-color);
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  display: flex;
}

.grid_2_cols .nft_item {
  min-width: 50%;
  max-width: 50%;
  width: 50%;
}

.grid_3_cols .nft_item {
  min-width: 33.33%;
  max-width: 33.33%;
  width: 33.33%;
}

.grid_4_cols .nft_item {
  min-width: 25%;
  max-width: 25%;
  width: 25%;
}

.nft_item {
  flex: 1;
  padding: 0 10px 40px;
  text-decoration: none;
}

.nft_item:hover .nft_thumbnail {
  transition: all .5s;
  transform: scale(1.2);
}

.nft_content {
  width: 100%;
  border-radius: 10px 10px 0 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.nft_thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.nft_stats {
  background: #f0f0f0;
  border-radius: 0 0 10px 10px;
  justify-content: flex-end;
  padding: 5px 10px;
  display: flex;
}

.nft_stats_item {
  color: gray;
  align-items: center;
  font-size: .7em;
  display: flex;
}

.nft_stats svg {
  width: 24px;
  fill: gray;
  margin-left: 5px;
}

.nft_info {
  color: #000;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  padding: 15px;
  text-decoration: none;
  display: flex;
  position: relative;
  box-shadow: 0 1px 1px #00000008, 0 3px 6px #00000005;
}

.nft_price {
  border-top: 1px solid #d3d3d3;
  align-items: center;
  margin-top: 15px;
  padding-top: 10px;
  display: flex;
}

.nft_title {
  flex: 1;
  margin: 15px 0 5px;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.2;
}

.nft_description {
  color: #333;
  font-size: .8em;
}

.nft_amount_sep {
  color: gray;
  margin: 0 10px;
}

.nft_amount {
  color: #000;
  align-items: baseline;
  font-size: 1.75em;
  font-weight: 800;
  display: flex;
}

.nft_amount .currency {
  aheight: 12px;
  fill: #000;
  margin-left: 3px;
  font-size: .75em;
  font-weight: 600;
  line-height: 1.2;
}

.nft_popup_hide {
  right: -350px !important;
}

.nft_popup {
  z-index: 5;
  min-height: 100%;
  width: 300px;
  background: #f0f0f0;
  flex-direction: column;
  transition: all .5s;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: -7px 0 25px #00000047;
}

.nft_popup_top {
  color: #fff;
  background: var(--primary-color);
  margin-bottom: 0;
}

.nft_popup_bar {
  align-items: center;
  padding: 10px;
  display: flex;
}

.nft_popup_bar a {
  color: #fff;
  fill: #fff;
  line-height: 1;
}

.nft_popup_bar svg {
  width: 18px;
  height: 18px;
}

.nft_popup_body {
  flex: 1;
  padding: 20px;
}

.nft_popup_bottom {
  padding: 20px;
  display: flex;
}

.nft_popup_bottom a {
  text-align: center;
  border-radius: 0;
  flex: 1;
  padding: 20px 10px !important;
}

.nft_popup_image_wrapper {
  padding-top: 50%;
  position: relative;
}

.nft_edit_title {
  flex: 1;
  margin-right: 10px;
  font-size: 1em;
  font-weight: 400;
}

.nft_edit_link {
  word-break: break-word;
  color: var(--secondary-text-color);
  margin-bottom: 5px;
  font-size: .75em;
  line-height: 1.2;
  display: block;
}

.nft_edit_links {
  margin-bottom: 30px;
}

.nft_popup_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.nft_popup input, .nft_popup select {
  afont-size: .75em;
}

.account_panel {
  display: flex;
}

.account_content {
  height: 100%;
}

.account_page footer {
  margin-top: 50px;
  padding: 20px 0;
}

.account_page .footer_text {
  margin: 0;
}

.account_page .footer_text_info {
  display: none;
}

.account_menu {
  width: 240px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  flex-direction: column;
  padding: 10px 50px 10px 10px;
  font-size: .85em;
  font-weight: 600;
  display: flex;
  position: sticky;
  top: 100px;
}

.account_link {
  color: var(--gray-color);
  border-bottom: 1px solid var(--gray-color);
  fill: var(--gray-color);
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  padding: 20px 0;
  display: flex;
}

.account_link:last-child {
  border: none;
}

.account_link svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.account_link.active {
  fill: var(--secondary-color);
  color: var(--secondary-color);
}

.account_section {
  padding-top: 100px;
}

.account_section_title {
  display: none;
}

.account_panel_right {
  width: 100%;
  background: #f3f3f3;
  border-radius: 10px;
  padding: 30px 40px 60px;
}

.account_title {
  background: var(--primary-color);
  color: #fff;
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 10px 20px;
  font-size: 1.5em;
  font-weight: 600;
}

.account_row {
  gap: 50px;
  display: flex;
}

.account_col_content {
  flex: 2;
}

.account_col_content_ext {
  flex: 3;
}

.account_col_side {
  flex: 1;
}

#settings.account_content {
  margin-top: 20px;
}

#settings label {
  margin-bottom: 10px;
  font-size: .8em;
}

#settings input, #settings textarea, #settings select {
  margin-bottom: 40px;
  padding: 10px;
}

#settings input:disabled {
  color: gray;
  background: #fff;
}

#account_page .btn_default {
  margin-top: 10px;
  padding: 8px 15px;
  font-size: .9em;
}

.account_stats {
  awidth: fit-content;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  display: flex;
}

.account_stats_item {
  padding: 0 0 10px;
}

.account_stats_title {
  background: var(--primary-color);
  color: #fff;
  flex: 1;
  margin-bottom: 0;
  padding: 2px 10px;
  font-size: .8em;
}

.account_stats_data {
  background: #fff;
  padding: 15px 20px 15px 10px;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1;
}

.account_stats_data .small {
  font-size: .75em;
  font-weight: 400;
}

.account_stats_data .smaller {
  color: gray;
  margin-top: 10px;
  font-size: .6em;
  font-weight: 400;
  line-height: 1.3;
}

.account_stats_plan {
  margin: 10px 0;
  font-size: .5em;
  font-weight: 400;
  line-height: 1.2;
}

.account_actions_row {
  display: flex;
}

.account_actions_result {
  flex: 1;
}

.transactions_parameters {
  align-items: center;
}

.transactions_parameters .col:last-child {
  text-align: right;
}

.transactions_parameters .btn_default {
  display: inline-block;
}

#transactions_list {
  width: 100%;
  min-height: 60vh;
  border-spacing: 1px;
  border-collapse: collapse;
  text-align: left;
  background: #fff;
  margin: 20px 0;
  font-size: .9rem;
}

th {
  background: var(--primary-color);
  color: #fff;
  width: 25%;
  border-right: 1px solid #fff;
  padding: 5px 10px;
  font-weight: normal;
}

th:last-child {
  text-align: right;
}

td {
  padding: 5px 10px;
}

tr {
  border-bottom: 1px solid #ddd;
}

td span {
  display: block;
}

.transactions_empty {
  text-align: center;
  color: gray;
  font-size: 1.25em;
}

@media (max-width: 768px) {
  body {
    font-size: 18px;
  }

  .no_mobile {
    display: none;
  }

  .mobile {
    display: block;
  }

  .nav_menu {
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid var(--primary-color);
    z-index: 4;
    background: #fff;
    border-radius: 0 15px 15px;
    padding: 20px 30px 10px 20px;
    display: none;
    position: absolute;
    top: 55px;
    left: 28px;
    box-shadow: 0 4px 12px #00000026;
  }

  nav {
    height: 55px;
    position: fixed;
  }

  .nav_menu a {
    text-align: left;
    border-bottom: 1px solid #d3d3d3;
    margin: 0 0 10px;
    padding: 0 0 10px;
    font-size: 1em;
    display: block;
  }

  .nav_menu a:last-child {
    border: none;
  }

  .nav_menu a:after {
    content: "";
    margin: 5px;
  }

  nav .flex {
    justify-content: start;
    align-items: center;
  }

  .nav_brand {
    flex: 1;
    padding-left: 10px;
  }

  .nav_lang {
    margin-right: 20px;
  }

  .hamb {
    cursor: pointer;
    width: 25px;
    height: 16px;
    padding: 0;
    display: block;
    margin-bottom: 0 !important;
  }

  .hamb-line {
    background: var(--dark-color);
    height: 2px;
    width: 25px;
    display: block;
    position: relative;
    top: 9px;
  }

  .hamb-line:before, .hamb-line:after {
    background: var(--dark-color);
    content: "";
    height: 100%;
    width: 100%;
    transition: all .2s ease-out;
    display: block;
    position: absolute;
  }

  .hamb-line:before {
    top: 7px;
  }

  .hamb-line:after {
    top: -7px;
  }

  .side-menu:checked ~ #nav_menu {
    display: block;
  }

  .side-menu:checked ~ .hamb .hamb-line {
    background: none;
  }

  .side-menu:checked ~ .hamb .hamb-line:before {
    top: 0;
    transform: rotate(-45deg);
  }

  .side-menu:checked ~ .hamb .hamb-line:after {
    top: 0;
    transform: rotate(45deg);
  }

  h1 {
    line-height: 1.1;
  }

  section h2 {
    max-width: 100%;
    padding-right: 0;
  }

  h3 {
    margin-bottom: 20px;
  }

  .content, .content_inside {
    padding: 0 20px;
  }

  .feature_header {
    font-size: 1.25em;
  }

  .row, .row_fields, .upload_row {
    flex-direction: column;
  }

  .upload_error {
    margin-top: 30px;
  }

  .upload_form {
    padding: 40px 15px;
  }

  .upload_form .btn_default {
    width: 100%;
    text-align: center;
  }

  header {
    padding-top: 80px;
  }

  header .flex {
    flex-direction: column-reverse;
  }

  .header_text {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  footer {
    padding-bottom: 50px;
  }

  .footer_text {
    max-width: 100%;
  }

  #how_works .row {
    gap: 0;
    padding-bottom: 40px;
  }

  #faq {
    padding-top: 20vw;
  }

  .brand_gradient {
    padding-top: 25vw;
  }

  .row_plans {
    gap: 0;
  }

  .row_the_plans {
    gap: 50px;
  }

  .nft_popup {
    min-height: auto;
    width: calc(100% - 40px);
    inset: auto 20px 0;
    box-shadow: 0 -7px 25px #00000047;
  }

  .nft_popup_hide {
    bottom: -100% !important;
  }

  .nft_edit_link {
    margin-bottom: 10px;
  }

  body.account_page {
    background: #f3f3f3;
  }

  .account_content {
    height: 100%;
  }

  .account_section {
    padding: 70px 0 0;
  }

  .account_section_title {
    margin-bottom: 20px;
    display: block;
  }

  .account_menu {
    width: 100%;
    z-index: 3;
    background: #fff;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-radius: 0;
    flex-flow: wrap;
    gap: 5px;
    padding: 0;
    position: fixed;
    top: 55px;
    left: 0;
  }

  .account_link {
    border: none;
    flex-direction: column;
    flex: 1;
    gap: 4px;
    padding: 15px 0;
    font-size: .7em;
    line-height: 1;
  }

  .account_link.active {
    fill: #fff;
    color: #fff;
    background: var(--secondary-color);
  }

  .account_link svg {
    width: 30px;
    height: 30px;
    margin-right: 0;
  }

  .account_link_text {
    display: none;
  }

  .account_panel {
    flex-direction: column;
  }

  .account_panel_right {
    margin-top: 70px;
    padding: 0;
  }

  .grid {
    flex-direction: column;
  }

  .nft_item {
    min-width: 100% !important;
  }

  .account_stats {
    flex-direction: column;
  }

  .account_stats_data {
    font-size: 1.5em;
  }

  .account_stats_item {
    flex-direction: row;
  }

  .account_row {
    flex-direction: column;
  }

  .account_row.reverse {
    flex-direction: column-reverse;
  }

  .transactions_parameters {
    gap: 0;
    margin-bottom: 30px;
  }

  .transactions_parameters .col {
    width: 100%;
    padding: 10px 0;
  }

  .transactions_parameters input {
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #32325d1a;
    border-radius: 6px;
    margin-bottom: 12px;
    padding: 12px;
    font-size: 1em;
  }

  fieldset {
    padding: 0;
  }

  #nft_uploading {
    gap: 0;
    margin-top: 30px;
  }

  .progress_bar_wrapper {
    height: 40px;
  }

  .progress_value {
    top: 8px;
    left: 15px;
  }
}

/*# sourceMappingURL=index.3e2ed17a.css.map */
